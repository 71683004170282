<template>
	<br-generic-list-base :derived-component="_self">
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "staffList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./StaffForm.vue"),
			modelName: "Staff",
			fromLoader: {
				apiBaseUrl: "/staffs/",
			},
			cols: {
				first_name: {
					fieldNamePaths: "user.firstName",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				last_name: {
					fieldNamePaths: "user.lastName",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				coach_calc_hasRole: {
					fieldNamePaths: "coach_calc_hasRole",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				coach_insuranceStuff_currentSession_isChecked: {
					fieldNamePaths: "coach_insuranceStuff_currentSession_checked_d",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model,defaultContent) { return this.t(`cols.coach_insuranceStuff_currentSession_isChecked.${defaultContent?"ok":"mustRenew"}`); },
					isVisible(col) { return this.$bREST.businessConfig.custom.coach_insuranceStuff_has; },
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					quickConnect: {
						click: {
							isEnabled(action,model) { return !!model.select("user.sudoHash").data; },
							async hook(action,model,isCtrlClickOrMiddleClick)
							{
								await this.$bREST.sudoIn(model.select("user.sudoHash").data);
								return true;
							},
						},
						icon: "mdi-account-switch",
					},
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				calc_flatSearch: {fieldNamePath:"calc_flatSearch"},
				phone: {fieldNamePath:"phone"},
				coach_calc_hasRole: {fieldNamePath:"coach_calc_hasRole"},
				configCity_fk: {fieldNamePath:"configCity_fk"},
				coach_insuranceStuff_currentSession_checked_d: {
					fieldNamePath: "coach_insuranceStuff_currentSession_checked_d",
					op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_NULL,
					isVisible(filter) { return this.$bREST.businessConfig.custom.coach_insuranceStuff_has; },
				},
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>